import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicExtended from "ckeditor5-build-classic-extended";
import Button from '../buttons/default';
import RemarksAttributionLink from './remarksAttributionLink';
import { newTempId } from '../../helpers';
import RemarksAttributedList from './remarksAttributedList';
import RemarksAttributionTypeLink from './remarkAttributionTypesLink';
const _api = require('../../api');

const TextEditor = (props) => {

	const toolbar = ['italic', 'bold', 'link', 'superscript', 'fontColor'];
	const Headingtoolbar = ['italic', 'bold', 'link', 'superscript', 'heading', 'fontColor', 'fontSize'];
	const placeholder = props.placeholder || '';
	const wordLimit = props.limit || 250;
	const [wordCount, setWordCount] = useState(props?.data?.split(' ')?.length || 0);
	const button = props.button || null;
	const [attribute, showAttribute] = useState(false)
	const [selectedText, setSelectedText] = useState('')
	const [attributionInfo, setAttributionInfo] = useState([])

	useEffect(() => {
		// eslint-disable-next-line 
		getAllRemarksAttributionByEntityId()
		// eslint-disable-next-line 

	}, [])
	const getAllRemarksAttributionByEntityId = async () => {
		if (props?.entity_id && props?.entity_id != "NEW") {
			const attributionData = await _api.remarks_attribution.getRemarksAttributionById(props?.entity_id)
			setAttributionInfo(attributionData)
		}
		else {
			setAttributionInfo([])
		}
	}
	const handleChange = (e, editor) => {
		const data = editor.getData();
		setWordCount(data.split(' ').filter(word => word).length);
		props.onChange(data);
	}

	const handleAnchorSelectedText = async (link) => {
		const name = link.name.replaceAll(" ", "_")
		const data = props?.homePage ? props.data.replace(selectedText, `<a style="text-decoration: none;"  href=${link.type === 'other' ? `${link.link}` : `${process.env.REACT_APP_WEB_URL}${link?.type === 'organization' || link?.type === 'publication' || link?.type === 'person' ? 'people' : link?.type}/${link?.vanity_url || link?.id}?name=${name}&id=${link.id}&type=${link?.type}`} style="text-decoration: none;" target='_blank'><span style="font-weight: bold; color: #a82829; text-decoration: none">${selectedText}</span></a>`)
			: props.data.replace(selectedText, `${selectedText}<a name=${name} href = ${link.type === 'other' ? `${link.link}` : `${process.env.REACT_APP_WEB_URL}people/${link?.vanity_url || link?.id}?name=${name}`}><sup>[${attributionInfo?.length + 1}]</sup></a>`)

		await _api.remarks_attribution.updateRemarksAttributionById({
			"id": newTempId(),
			"entity": props?.type,
			"entity_id": props?.entity_id,
			"tag_type": link?.type,
			"tag_id": link?.type !== 'other' ? link?.id : null,
			"tag_text": link?.name,
			"highlighted_text": selectedText,
			"date_id": props?.date_id
		})

		props.onChange(data);


		setTimeout(() => {

			getAllRemarksAttributionByEntityId()
		}, 3000);

	}

	const removeAnchorSuperScriptTag = (link, index) => {
		// const data = props.data.replace(`<a href = ${link.type === 'other' ? `${link.link}` : `${process.env.REACT_APP_WEB_URL}people/${link?.vanity_url || link?.id}`}>${link.highlighted_text}<sup>[${index + 1}]</sup></a>`, link.highlighted_text)
		// props.onChange(data);
		getAllRemarksAttributionByEntityId()
	}


	return (

		<>
			<div className='d-flex justify-content-between'>
				<div className=''>
					{props.children}
				</div>
				<div className=''>
					<label className='cnow-form-word-count'>
						{wordCount > 0 ? `${wordCount} of` : `Limit`} {wordLimit} words
					</label>
					{button?.reset?.show && <Button size='sm' color='light' icon='Symbols_Repeat' className={`ms-1`} onClick={button?.reset?.onHandle} />}
					{props?.showRemarksAttribution && <Button size='sm' color='light' icon='Symbols_Repeat' label={attributionInfo?.length + 1} className={`ms-1`} onClick={() => { showAttribute(true); setSelectedText(window.getSelection().toString()) }} />}
				</div>

			</div>
			<div className='border bg-white'>
				<CKEditor config={{
					fontColor: {
						colors: [
							{
								color: 'hsl(0, 0%, 0%)',
								label: 'Black'
							},
							{
								color: 'hsl(0, 0%, 60%)',
								label: 'Grey'
							},
							{
								color: 'hsl(0, 0%, 100%)',
								label: 'White',
								hasBorder: true
							},
							{
								color: '#a82829',
								label: 'Red',
								hasBorder: true
							},
						]
					}, allowedContent: true, toolbar: props?.heading ? Headingtoolbar : toolbar, autoParagraph: false, placeholder: placeholder
				}}
					data={props.data}
					onChange={handleChange}
					editor={ClassicExtended}
				/>
			</div>
			{props.showRemarksAttribution && attributionInfo.filter((elem) => elem.date_id === props?.date_id)?.length > 0 && <RemarksAttributedList data={attributionInfo.filter((elem) => elem.date_id === props?.date_id)} getUpdatedData={(data, index) => { removeAnchorSuperScriptTag(data, index) }} homePage={true} />}
			{props.showRemarksAttribution && attribute && selectedText && !props?.homePage && <RemarksAttributionLink handleLinkUpdate={(data) => { handleAnchorSelectedText(data) }} setSelectedText={setSelectedText} selectedText={selectedText} />
			}
			{props.showRemarksAttribution && attribute && selectedText && props?.homePage && <RemarksAttributionTypeLink handleLinkUpdate={(data) => { handleAnchorSelectedText(data) }} setSelectedText={setSelectedText} selectedText={selectedText} />}
		</>
	);
};

export default TextEditor;
